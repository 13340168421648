<template>
	<div class="section offers" v-if="this.merklisten.length>0">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<h5 class="post-heading">Ähnliche Merklisten</h5>
				</div>

				<div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="(item, index) in merklisten" :key="index">
					<merkliste-card :item="item" :autoWidth="true"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { extractMerklisten } from '@/utils/lupe-helpers';
	import { showLoader, hideLoader } from '@/utils/helpers';

	export default {
		name: 'randomMerklisten',
		components: {
			MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
		},
		props: {
			exclude: {
				type: Number,
				default: null,
			}
		},
		data() {
			return {
				merklisten: null,
			}
		},
		created() {
			this.getMerklisten();
		},
		methods: {
			showLoader, 
			hideLoader,
			extractMerklisten,
			getMerklisten() {
				var contentLoader = this.showLoader(contentLoader);
				this.$store.dispatch('loadCollections').then(response => {
					this.merklisten = this.extractMerklisten(response,4,this.exclude);
				})
				.finally(() => {
					contentLoader = this.hideLoader(contentLoader);
				});
			},
		}

	}
</script>